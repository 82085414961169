<template>
	<div class="container">
		<div class="card">
			<h1 class="title-search v-mobile">{{$t("titles.settings")}}</h1>
			<div class="d-flex buttons row">
				<div class = btn-phone>
					<Popper
						class="dropdown d-block"
						trigger="clickToToggle"
						:options="{ placement: 'bottom' }"
						:visible-arrow="false"
					>
						<div class="dropdown-menu">
							<div class="dropdown-choices">
								<button
									v-for="(color, name) in themes"
									:class="{ 'dropdown-item': true, disabled: themeAlreadyUsed(name) }"
									type="button"
									:key="name"
									:value="name"
									v-on:click = "themeAlreadyUsed(name) ? null : changeTheme(name)"
								>
									<div class="cadre-color" :style="'background:' + color">
										<span v-if="themeAlreadyUsed(name)">{{ themeUsedInitials(name) }}</span>
									</div>
								</button>
							</div>
						</div>
						<StatusButton
							id="planning-theme"
							ref="themeButton"
							slot="reference"
							aria-expanded="false"
							aria-haspopup="true"
							type="primary dropdown-toggle"
							data-flip="false"
							data-toggle="dropdown"
							trigger="none"
							:speed="750"
						>{{ $t("settings.acctheme") }}</StatusButton>
					</Popper>
				</div>
				<h1 class="title-search v-desktop">{{$t("titles.settings")}}</h1>
				<div class="first-btn">
					<Popper
						class="dropdown d-block"
						trigger="clickToToggle"
						:options="{ placement: 'bottom' }"
						:visible-arrow="false"
					>
						<div class="dropdown-menu">
							<div class="dropdown-choices">
								<button
									v-for="color in colors"
									:key="color"
									:class="{ 'dropdown-item light': true, disabled: colorAlreadyUsed(color) }"
									type="button"
									:value="color"
									v-on:click = "colorAlreadyUsed(color) ? null : changeColor(color)"
								>
									<div class="cadre-color" :style="'background:' + color">
										<span v-if="colorAlreadyUsed(color)">{{ colorUsedInitials(color) }}</span>
									</div>
								</button>
							</div>
						</div>
						<StatusButton
							id="color-btn"
							ref="colorButton"
							slot="reference"
							aria-expanded="false"
							aria-haspopup="true"
							type="primary dropdown-toggle"
							data-flip="false"
							data-toggle="dropdown"
							trigger="none"
						>{{ $t("settings.schtheme") }}</StatusButton>
					</Popper>
				</div>
			</div>
			<div class="row">
				<div class="row column-reverse-mobile w-100">
					<div class="col-md-6">
						<h2 id="holiday" class="title-search2">{{ $t("titles.calendar") }}</h2>
						<div class="settings">
							<ToggleSwitch ref="showPastAppointments" :value="show_past_appointments" @change="setParameters({show_past_appointments: $event})">{{ $t("settings.pastapps") }}</ToggleSwitch>
						</div>
						<h2 id="holiday" class="title-search2">{{ $store.state.worker.username == "general" ? $tc("administration.salonclose", 2) : $t("common.hol") }}</h2>
						<div class="missing">
							<div v-for="absence in absences" :key="absence.id" class="holiday">
								<div class="row">
									<div class="col-lg-8">
										<i18n
											v-if="$dayjs(absence.start).add(1, 'day').format('DD-MM-YYYY') === $dayjs(absence.end).format('DD-MM-YYYY') &&
												!hasHrMn(absence.start) && !hasHrMn(absence.end)"
											tag="span"
											path="time.exact"
										>
											<template v-slot:date>
												{{ $d( absence.start, !hasHrMn(absence.start) ? "numericonlydate" : "numeric" ) }}
											</template>
										</i18n>
										<i18n
											v-else
											tag="span"
											:path="absenceI18nPath(absence)"
										>
											<template v-slot:start>{{ $d( absence.start, ( absence.start.getHours() + absence.start.getMinutes() == 0 ) ? "numericonlydate" : "numeric" ) }}</template>
											<template v-slot:end>
												{{ $d( $dayjs(absence.end).add(!hasHrMn(absence.end) ? -1 : 0, 'day'),
													!hasHrMn(absence.end) ? "numericonlydate" : "numeric" )
												}}
											</template>
										</i18n>
										<div v-if="absence.message" class="message-hol">« {{absence.message}} »</div>
									</div>
									<div class="buttons-holiday col-lg-4">
										<StatusButton
											alert="modal" type="outline-quaternary btn-holt"
											@click="showDeleteConfirm = { callback: $event, redir: true, absence }"
											v-if="$dayjs(absence.start).isAfter($dayjs())"
										><i class="fas fa-edit"></i></StatusButton>
										<StatusButton
											alert="modal" type="outline-quaternary btn-hol"
											@click="showDeleteConfirm = { callback: $event, absence }"
											v-if="$dayjs(absence.start).isAfter($dayjs())"
										><i class="fas fa-trash"></i></StatusButton>
									</div>
								</div>
							</div>
						</div>
						<div class="no-data-hint" v-if="!absences.length">{{ $t('common.noholiday') }}</div>
					</div>
					<div v-if="$store.state.worker.username != 'general'" class="col-md-6">
						<div id="login" class="container">
							<h2 class="title-search2 title-log">{{$t("common.login")}}</h2>
							<form id="edit-form" class="block login form" method="POST" @submit.prevent="">
								<div class="input-group form-group">
									<div class="input-group-prepend">
										<span class="input-group-text">{{$t("common.name")}}</span>
									</div>
									<input class="form-control email" name="lname" type="text" v-model="editname" v-on:input="showsave=true" >
								</div>
								<div class="input-group form-group">
									<div class="input-group-prepend">
										<span class="input-group-text">{{$t("common.email")}}</span>
									</div>
									<input class="form-control email" name="email" type="text" v-model="editemail" v-on:input="showsave=true" >
								</div>
								<StatusButton type="primary" :hidden="!showsave" :onclick="saveuser">{{ $t('common.save') }}</StatusButton>
							</form>
						</div>
						<div class="container">
							<h2 id="title-pass" class="title-search3">{{$t("common.chpass") }}</h2>
							<div class="form">
								<div v-if="!$store.getters.salonHasUniqueAccount() && $store.state.worker.admin" class="pass-salon">
									<span @click="$refs.passwordMode.setChecked(false)">Compte</span>
									<ToggleSwitch ref="passwordMode" :color="themes[$store.state.workers.find(w => w.username == 'general').theme]" @change="salonPass = $event"></ToggleSwitch>
									<span @click="$refs.passwordMode.setChecked(true)">Salon</span>
								</div>
								<input id="old-pass" class="form-control confirm-pass" :placeholder="salonPass ? $t('common.yourpwd') : $t('common.currentpwd')" type="password" v-model="currentpass">
								<input id="new-pass" class="form-control confirm-pass" :placeholder="$t('common.npasswd')" type="password" v-model="newpass">
								<input id="confirm-pass" class="form-control confirm-pass" :placeholder="$t('common.confirmpwd')" type="password" v-model="confirmpass">
								<StatusButton type="primary" :hidden="!passerror && !currentpass && !newpass && !confirmpass" :onclick="savepassword">{{ $t('common.save') }}</StatusButton>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-12 d-flex">
					<h2 class="title-search2 title-log title-notif notif">{{$t("common.notifs")}}</h2>
				</div>
				<div v-if="!$store.getters.salonHasUniqueAccount()" class="col-md-6 col-notifs">
					<h3 class="title-search2 title-log notif">{{$t("administration.team")}}</h3>
					<ToggleSwitch v-for="( notif, id ) in $t( 'data.notifications' )" v-if="!notif.mendatory && ( $store.state.worker.admin || !notif.admin ) && ( !notif.premium || ['premium','multi'].includes($store.state.salon.subscription) ) && notif.message.indexOf('worker') >= 0" :key="notif.id" class="notif-setting" :value="notifEnabled( id )" @change="notifChange( id, $event )">
						«
						<i18n :path="`data.notifications['${id}'].message`">
							<template v-slot:date>
							</template>
							<template v-slot:worker>
								<i class="worker">{{ $t('common.worker') }}</i>
							</template>
							<template v-slot:user>
								<i class="client">{{ $t('common.client') }}</i>
							</template>
							<template v-slot:data.user>
								<i class="client">{{ $t('common.client') }}</i>
							</template>
							<template v-slot:data.name>
								<i class="worker">{{ $t('common.worker') }}</i>
							</template>
							<template v-slot:action>
							<b>{{ $t( `data.notifications['${id}'].action`) }}</b>
							</template>
							<template v-slot:data.date>
								<i>{{ $t('common.date') }}</i>
							</template>
						</i18n>
						»
					</ToggleSwitch>
				</div>
				<div class="col-md-6 col-notifs">
					<!--h3 class="title-search2 title-log notif">{{$t("common.customers")}}</h3-->
					<ToggleSwitch v-for="( notif, id ) in $t( 'data.notifications' )" v-if="!notif.mendatory && ( $store.state.worker.admin || !notif.admin ) && ( !notif.premium || ['premium','multi'].includes($store.state.salon.subscription) ) && notif.message.indexOf('worker') < 0 && notif.message.indexOf('user') >= 0" :key="notif.id" class="notif-setting" :value="notifEnabled( id )" @change="notifChange( id, $event )">
						«
						<i18n :path="`data.notifications['${id}'].message`">
							<template v-slot:date>
							</template>
							<template v-slot:worker>
								<i class="worker">{{ $t('common.worker') }}</i>
							</template>
							<template v-slot:user>
								<i class="client">{{ $t('common.client') }}</i>
							</template>
							<template v-slot:data.user>
								<i class="client">{{ $t('common.client') }}</i>
							</template>
							<template v-slot:action>
							<b>{{ $t( `data.notifications['${id}'].action`) }}</b>
							</template>
							<template v-slot:data.date>
								<i>{{ $t('common.date') }}</i>
							</template>
						</i18n>
						»
					</ToggleSwitch>
					<!--h3 class="title-search2 title-log notif" v-if="shownOtherNotifs.length">{{$t("common.others")}}</h3>
					<div class="notifs-other">
						<ToggleSwitch v-for="[id, notif] in shownOtherNotifs" :key="id" v-if="!notif.stocks || $store.getters.salonHasStocks()" class="notif-setting" :value="notifEnabled( id )" @change="notifChange( id, $event )">
							«
							<i18n :path="`data.notifications['${id}'].message`">
								<template v-slot:date>
								</template>
								<template v-slot:action>
								<b>{{ $t( `data.notifications['${id}'].action`) }}</b>
								</template>
								<template v-slot:data.date>
									<i>{{ $t('common.date') }}</i>
								</template>
							</i18n>
							»
						</ToggleSwitch>
					</div-->
					<StatusButton v-if="shownotifsave" type="primary" :onclick="savenotif">{{ $t('common.save') }}</StatusButton>
				</div>
			</div>
			<!--div class="row">
				<div class="col-md-6 settings-locale">
					<h2 class="title-search2 title-log">{{$t("settings.language")}}</h2>
					<Popper ref="langPopper" id="language-btn" class="dropdown btn" trigger="clickToToggle" :options="{ placement: 'bottom' }" :visible-arrow="false">
						<div class="dropdown-menu">
							<div class="dropdown-choices" @click="$refs.langPopper.doClose()">
								<button
									v-for="( locale, name ) in $i18n.messages"
									:key="name"
									class="dropdown-item lang"
									type="button"
									:value="name"
									v-on:click="changeLocale( name )"
								>
									<span class="locale">{{ locale.lang }}</span>
									<img v-for="icon in locale.icons" :src="icon">
								</button>
							</div>
						</div>
						<StatusButton
							ref="localeButton"
							slot="reference"
							aria-expanded="false"
							aria-haspopup="true"
							type="secondary dropdown-toggle lang"
							data-flip="false"
							data-toggle="dropdown"
							trigger="none"
						>
							<span class="locale">{{ $t("lang") }}</span>
							<img v-for="icon in $t('icons')" :src="icon">
						</StatusButton>
					</Popper>
				</div>
			</div-->
		</div>
		<Confirm v-if="showDeleteConfirm" @confirm="deleteAbsence" @close="showDeleteConfirm.callback( -1 ); showDeleteConfirm = false" ref="deleteConfirm">
			<template v-if="showDeleteConfirm.redir">
				{{ $t('vacation.confirmredirect') }}
			</template>
			<template v-else>
				{{ $t('vacation.confirmdelete') }}
			</template>
		</Confirm>
	</div>
</template>

<script>
	import dayjs from "dayjs"
	import api from "../apiClient"
	import Popper from "vue-popperjs"
	import ToggleSwitch from "../components/ToggleSwitch.vue"
	import StatusButton from "../components/StatusButton.vue"
	import Confirm from "../modals/Confirm.vue"
	import constants from "../constants.js"

	export default {
		components: {
			Popper,
			ToggleSwitch,
			StatusButton,
			Confirm
		},
		data() {
			return {
				absences: [],
				themes: constants(this.$store.state.domain).themes,
				colors: constants(this.$store.state.domain).colors,
				editname: "",
				editemail: "",
				salonPass: false,
				currentpass: "",
				newpass: "",
				confirmpass: "",
				passerror: false,
				showsave: false,
				disabled_notifs: [],
				shownotifsave: false,
				showDeleteConfirm: false,
				show_past_appointments: false
			}
		},
		computed: {
			shownOtherNotifs() {
				return Object.entries(this.$t('data.notifications')).filter(([, notif]) => !notif.mendatory &&
					( this.$store.state.worker.admin || !notif.admin ) &&
					( !notif.premium || ['premium','multi'].includes(this.$store.state.salon.subscription) ) &&
					notif.message.indexOf('worker') < 0 && notif.message.indexOf('user') < 0
				)
			},
			worker() {
				return this.$store.state.worker
			},
		},
		watch: {
			'$route'( to, from ) {
				this.currentpass = ""
				this.newpass = ""
				this.confirmpass = ""
				if ( to.path.indexOf( "/settings" ) == 0 ) {
					this.update()
				}
			}
		},
		methods: {
			themeAlreadyUsed( name ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					if ( this.$store.state.workers[i].theme == name ) {
						return true
					}
				}
				return false
			},
			themeUsedInitials( name ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					const worker = this.$store.state.workers[i]
					if ( worker.theme == name ) {
						return ( worker.fname || "" ).substr(0, 1).toUpperCase() + ( worker.lname || "" ).substr(0, 1).toUpperCase()
					}
				}
				return ""
			},
			colorAlreadyUsed( color ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					if ( this.$store.state.workers[i].color == color ) {
						return true
					}
				}
				return false
			},
			colorUsedInitials( color ) {
				for ( let i = 0; i < this.$store.state.workers.length; i++ ) {
					const worker = this.$store.state.workers[i]
					if ( worker.color == color ) {
						return ( worker.fname || "" ).substr(0, 1).toUpperCase() + ( worker.lname || "" ).substr(0, 1).toUpperCase()
					}
				}
				return ""
			},
			changeTheme(name) {
				// Live theme change is done by store.js by "api.workers.watch"
				this.$refs.themeButton.clicked( "loading" );
				this.$api.worker.patch( this.$store.state.worker.id, {theme: name} ).then( response => {
					this.$refs.themeButton.clicked( "success" );
				}).catch( error => {
					this.$refs.themeButton.clicked( "error", error.response.data.error );
					console.log( error );
				});
			},
			changeColor(color) {
				this.$refs.colorButton.clicked( "loading" );
				this.$api.worker.patch( this.$store.state.worker.id, {color: color} ).then( response => {
					this.$refs.colorButton.clicked( "success" );
				}).catch( error => {
					this.$refs.colorButton.clicked( "error", error.response.data.error );
					console.log( error );
				});
			},
			changeLocale(name) {
				this.$refs.localeButton.clicked( "loading" );
				this.$api.worker.patch( this.$store.state.worker.id, {locale: name} ).then( response => {
					this.$refs.localeButton.clicked( "success" );
				}).catch( error => {
					this.$refs.localeButton.clicked( "error", error.response.data.error );
					console.log( error );
				});
			},
			setParameters( params ) {
				this.$api.worker.patch( this.$store.state.worker.id, params ).then( response => {
				}).catch( error => {
					console.log( error )
				})
			},
			deleteAbsence() {
				const id = this.showDeleteConfirm.absence.id
				this.$api.absence.delete( this.showDeleteConfirm.absence.id ).then( response => {
					
					setTimeout( () => {
						let idx = this.absences.findIndex(abs => id === abs.id)
						if (idx >= 0)
							this.absences.splice( idx, 1 )
					}, 1000 )
					if ( this.showDeleteConfirm.redir ) {
						this.$router.push( "/schedules#new-card" )
					}
					this.showDeleteConfirm.callback( true )
					this.showDeleteConfirm = false
				}).catch( error => {
					console.log( error )
					this.showDeleteConfirm.callback( false, error.response.data.error )
					this.showDeleteConfirm = false
				})
			},
			update() {
				this.editname = this.$store.state.worker.username
				this.editemail = this.$store.state.worker.email
				this.disabled_notifs = [ ...( this.$store.state.worker.disabled_notifications || [] ) ]
				this.show_past_appointments = this.$store.state.worker.show_past_appointments
				this.$api.absences.get({ worker: this.$store.state.worker.id }).then(response => {
					const absences = response.data
					absences.forEach(element => {
						element.start = new Date(element.start)
						element.end = new Date(element.end)
					})
					this.absences = absences.sort( ( a, b ) => dayjs(a.start).isBefore(dayjs(b.start)) ? 1 : -1 )
				}).catch(error => console.log(error))
			},
			savepassword( callback ) {
				let id = this.$store.state.worker.id
				if ( this.$store.state.worker.admin && this.salonPass ) {
					if ( this.newpass.length == 0 || this.confirmpass.length == 0 ) {
						callback( false, "errors.pass.missing" )
						this.passerror = true
						this.currentpass = ""
						this.newpass = ""
						this.confirmpass = ""
						return
					}
					if ( this.newpass !== this.confirmpass ) {
						callback( false, "errors.pass.mismatch" )
						this.passerror = true
						this.currentpass = ""
						this.newpass = ""
						this.confirmpass = ""
						return
					}
					this.$api.worker.patch( this.$store.state.workers.find( w => w.username == "general" ).id, { password: this.newpass, passwordCheck: this.currentpass } ).then( response => {
						this.currentpass = ""
						this.newpass = ""
						this.confirmpass = ""
						this.passerror = false
						callback( true )
					}).catch( error => callback( false, error.response.data.error ) )
				} else {
					this.$api.post(`/workers/${id}/updatepass`, {currentpass: this.currentpass, newpass:this.newpass, confirmpass:this.confirmpass}).then(response => {
						this.currentpass = ""
						this.newpass = ""
						this.confirmpass = ""
						this.passerror = false
						callback( true )
					}).catch(error => {
						this.passerror = true
						this.currentpass = ""
						this.newpass = ""
						this.confirmpass = ""
						let msg = ""
						if ( error.response.data.error.indexOf( "pass." ) < 0 ) {
							msg = error.response.data.error
						} else {
							msg = this.$t( 'errors.' + error.response.data.error )
						}
						callback( false, msg )
					})
				}
			},
			saveuser( callback ) {
				this.$api.worker.patch(this.$store.state.worker.id, { username:this.editname, email:this.editemail}).then( response => {
					let me = this;
					setTimeout( function() {
						me.showsave = false
					}, 3000 );
					callback( true );
				}).catch( error => {
					callback( false, this.$t( "errors." + ( error.response ? error.response.data.error : error.message ) ) );
				})
			},
			savenotif( callback ) {
				this.$api.worker.patch(this.$store.state.worker.id, { disabled_notifications: this.disabled_notifs }).then( response => {
					let me = this;
					setTimeout( function() {
						me.showsave = false
					}, 3000 );
					callback( true );
				}).catch( error => {
					callback( false, this.$t( "errors." + ( error.response ? error.response.data.error : error.message ) ) );
				})
			},
			notifEnabled( id ) {
				if ( !this.disabled_notifs.includes( parseInt(id) ) ) {
					return true;
				}
				return undefined;
			},
			notifChange( id, value ) {
				if ( value === true ) {
					let pos = this.disabled_notifs.indexOf( parseInt(id) );
					if ( pos >= 0 ) {
						this.disabled_notifs.splice( pos, 1 );
					}
				} else {
					this.disabled_notifs.push( parseInt(id) );
				}
				console.log( this.disabled_notifs );
				this.shownotifsave = true;
			},
			hasHrMn(date) {
				return date.getHours() !== 0 || date.getMinutes() !== 0
			},
			absenceI18nPath(absence) {
				if (this.hasHrMn(absence.start) && !this.hasHrMn(absence.end))
					return 'time.rangeToEndOfDay'
				else if (!this.hasHrMn(absence.start) && this.hasHrMn(absence.end))
					return 'time.rangeFromStartOfDay'
				return 'time.range'
			}
		},
		mounted() {
			this.update()
			/*
			this.$store.subscribe( ( mutation, state ) => {
				if ( mutation.type == "updateWorker" && mutation.payload.id === this.$store.state.worker.id ) {
					this.update()
				}
			})
			*/
			this.$api.absences.watch( event => {
				console.log( event )
				if ( event.method === "DELETE" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.absences.findIndex( abs => abs.id === parseInt(event.data[i].id) )
						if ( found ) {
							this.absences.splice( found, 1 )
						}
					}
				} else if ( event.method === "PATCH" ) {
					for ( let i = 0; i < event.data.length; i++ ) {
						let found = this.absences.find( abs => abs.id === parseInt(event.data[i].id) )
						if ( found ) {
							Object.assign( found, event.data[i] )
							found.start = new Date( found.start )
							found.end = new Date( found.end )
						}
					}
				} else if ( event.method === "POST" ) {
					let changed = false
					for ( let i = 0; i < event.data.length; i++ ) {
						if ( event.data[i].worker == this.$store.state.worker.id ) {
							event.data[i].start = new Date( event.data[i].start )
							event.data[i].end = new Date( event.data[i].end )
							this.absences.push( event.data[i] )
							changed = true
						}
					}
					if ( changed ) {
						this.absences = this.absences.sort( ( a, b ) => dayjs(a.start).isBefore(dayjs(b.start)) ? 1 : -1 )
					}
				}
			})
		}
		
	}
</script>


<style scoped lang="scss" src="../css/pages/settings.scss"/>
